import React from "react";
import Navbar from "../components/Navbar";
import Infect from "../components/Infect";
import Footer from "../components/Footer";

function InfectionPage() {
    return (
        <div>
            <Navbar />
            <Infect />
            <Footer />
        </div>
    );
}

export default InfectionPage;
