import React, { useState, useEffect } from "react";
import standardCorona from "../assets/images/Standard.png";

function Infect() {
    const [selectedVariantImg, setSelectedVariantImg] =
        useState(standardCorona);

    useEffect(() => {
        window.scrollTo(0, 0);

        const variant = JSON.parse(localStorage.getItem("selectedVariant"));

        setTimeout(() => {
            if (variant) {
                document.querySelector(".nav-list").classList.add("hide");
                document
                    .querySelector(".infect-section")
                    .style.setProperty("--selectedVarientColor", variant.color);
                setSelectedVariantImg(variant.img);
            }
        }, 100);
    }, []);

    const startLoading = () => {
        document.querySelector(".infect-btn").style.display = "none";
        const infectSubTitle = document.querySelector(".infect-sub-title");

        infectSubTitle.textContent = "Infecting your screen...";

        const circle = document.querySelector(".progress-ring__circle");
        const radius = circle.r.baseVal.value;
        const circumference = radius * 2 * Math.PI;

        circle.style.strokeDasharray = `${circumference} ${circumference}`;
        circle.style.strokeDashoffset = `${circumference}`;

        setTimeout(() => {
            circle.classList.add("animate");
        }, 100);

        const setProgress = (percent) => {
            const offset = circumference - (percent / 100) * circumference;
            circle.style.strokeDashoffset = offset;
        };

        const loadingProgress = document.querySelector(".loading-progress");

        let counter = 0;
        const interval = setInterval(() => {
            if (counter === 100) {
                clearInterval(interval);
                infectSubTitle.textContent = "Lick your screen to get Corona";
                loadingProgress.textContent = "Infected!";
                document.querySelector(".corona-bg-img").style.display =
                    "block";
            } else {
                counter += 1;
                setProgress(counter);
                loadingProgress.textContent = `${counter}%`;
            }
        }, 80);
    };

    return (
        <main>
            <section className="infect-section">
                <div className="container">
                    <div className="infect-content">
                        <h1 className="infect-title">Infect!</h1>
                        <h3 className="infect-sub-title">Infect Your Screen</h3>
                        <div className="loading-container paused">
                            <div className="corona-bg-img">
                                <img src={selectedVariantImg} alt="Corona" />
                            </div>

                            <div className="loading-progress">
                                <p>0%</p>
                            </div>

                            <svg
                                className="progress-ring"
                                width="280"
                                height="280"
                            >
                                <circle
                                    className="progress-ring__circle__bg"
                                    stroke="#fff"
                                    strokeWidth="20"
                                    fill="transparent"
                                    r="125"
                                    cx="140"
                                    cy="140"
                                />
                                <circle
                                    className="progress-ring__circle"
                                    strokeWidth="20"
                                    fill="transparent"
                                    r="125"
                                    cx="140"
                                    cy="140"
                                />
                            </svg>
                        </div>
                        <button
                            onClick={startLoading}
                            className="infect-btn btn"
                        >
                            Infect
                        </button>
                    </div>
                </div>
            </section>

            <section className="disclaimer-section">
                <div className="container">
                    <div className="disclaimer-content">
                        <h2 className="heading">Disclaimer</h2>
                        <p className="para-text">
                            This isn't a scam, there isn't a virus, it's just a
                            joke website. Don't lick your screen it may contain
                            harmful bacterias.
                        </p>
                    </div>
                </div>
            </section>
        </main>
    );
}

export default Infect;
