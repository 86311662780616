import React from "react";
import phoneIcon from "../assets/images/phone.svg";
import emailIcon from "../assets/images/email.svg";
import maskIcon from "../assets/images/mask.svg";

function Footer() {
    return (
        <footer id="footer">
            <div className="container">
                <div className="footer-content">
                    <h2 className="heading">Contact</h2>
                    <p className="para-text">
                        Want a lenghty and painful death? Want to support
                        natural selection? Want to kill your loved ones?
                    </p>

                    <p className="para-text">
                        Get Covid-19 easily using our patend pending Covid
                        Downloading Technology © just follow the instructions on
                        your screen!
                    </p>

                    <p className="para-text">
                        If you are stuck on any of these steps feel free to call
                        us!
                    </p>

                    <p className="vaccinate">
                        WEAR YOUR FUCKING MASK AND GET VACCINATED
                    </p>

                    <ul className="social-list">
                        <li>
                            <div className="social-link">
                                <img src={phoneIcon} alt="Phone" />
                                <p>1800-GET-VACCINATED</p>
                            </div>
                        </li>
                        <li>
                            <a
                                href="mailto:keepthisshitholerunning@covid.download"
                                className="social-link"
                            >
                                <img src={emailIcon} alt="Email" />
                                <p>keepthisshitholerunning@covid.download</p>
                            </a>
                        </li>
                        <li>
                            <a
                                href="https://www.who.int/health-topics/coronavirus"
                                target="_blank"
                                rel="noreferrer noopener"
                                className="social-link"
                            >
                                <img src={maskIcon} alt="WorldOMeter" />
                                <p>World Health Organization</p>
                            </a>
                        </li>
                    </ul>

                    <p className="copyright-text">
                        © Copyright 2020 All The Rights
                    </p>
                    <p className="vaccinate-2">
                        WEAR YOUR FUCKING MASK | GET THE FUCK VACCINATED | GET
                        FUCKING INFORMED | WASH YOUR FUCKING HANDS
                    </p>
                </div>
            </div>
        </footer>
    );
}

export default Footer;
