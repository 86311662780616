import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import standardCorona from "../assets/images/Standard.png";
import coronavariantsData from "../assets/variantData/variant";

function Home() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const coronavariants = coronavariantsData.map((variant) => (
        <div key={variant.id} className="corona-variant">
            <div className="corona-variant-img">
                <img src={variant.img} alt={variant.name} />
            </div>
            <p className="variant-name" style={{ color: `${variant.color}` }}>
                {variant.name}
            </p>

            <p className="variant-origin" style={{ color: `${variant.color}` }}>
                Originated In {variant.origin}
            </p>

            <Link
                onClick={() => selectedVarient(variant)}
                to="/infect"
                style={{ backgroundColor: `${variant.color}` }}
                className="select-btn btn"
            >
                Select Virus
            </Link>
        </div>
    ));

    const selectedVarient = (variant) => {
        localStorage.setItem("selectedVariant", JSON.stringify(variant));
    };

    return (
        <main>
            <section id="home" className="hero-section">
                <div className="container">
                    <div className="hero-content">
                        <h1 className="title">Get Corona Virus!</h1>
                        <h3 className="sub-heading">Instant, Fast, Free</h3>
                        <a href="#variant" className="hero-img">
                            <img src={standardCorona} alt="Standard Corona" />
                        </a>
                    </div>
                </div>
            </section>

            <section id="variant" className="variant-section">
                <div className="container">
                    <div className="variant-content">
                        <h2 className="heading">Infect</h2>
                        <p className="para-text">
                            Please select the variant of corona virus you would
                            like to get infected with.
                        </p>

                        <div className="corona-container">{coronavariants}</div>
                    </div>
                </div>
            </section>
        </main>
    );
}

export default Home;
