import standardImg from "../images/Standard.png";
import deltaImg from "../images/Delta.png";
import lambdaImg from "../images/Lambda.png";

const coronaVariantsData = [
    {
        id: 1,
        name: "Standard",
        img: standardImg,
        origin: "China",
        color: "#DA1E1B",
    },
    {
        id: 2,
        name: "Delta",
        img: deltaImg,
        origin: "India",
        color: "#026C3C",
    },
    {
        id: 3,
        name: "Omicron",
        img: lambdaImg,
        origin: "South Africa",
        color: "#C352EE",
    },
];

export default coronaVariantsData;
