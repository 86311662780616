import React from "react";
import logo from "../assets/images/Standard.png";
import { Link, useLocation } from "react-router-dom";

function Navbar() {
    // Handles Mobile Hamburger Menu
    const toggleMobileNav = () => {
        const hamburger = document.querySelector(".hamburger");
        const navList = document.querySelector(".nav-list");

        hamburger.classList.toggle("open");
        navList.classList.toggle("open");
    };

    const scrollToTop = () => {
        if (location.pathname !== "/infect") {
            window.scrollTo(0, 0);
        }
    };

    const location = useLocation();

    return (
        <header>
            <div className="container">
                <nav>
                    <Link to="/" onClick={scrollToTop} className="nav-logo">
                        <img src={logo} alt="Logo" />
                    </Link>
                    <ul onClick={toggleMobileNav} className="nav-list">
                        <li>
                            <Link
                                to="/"
                                onClick={scrollToTop}
                                className="nav-link"
                            >
                                Home
                            </Link>
                        </li>
                        <li>
                            <a href="#variant" className="nav-link">
                                Download
                            </a>
                        </li>
                        <li>
                            <a href="#footer" className="nav-link">
                                Contact
                            </a>
                        </li>
                    </ul>

                    <div onClick={toggleMobileNav} className="hamburger">
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                    </div>
                </nav>
            </div>
        </header>
    );
}

export default Navbar;
