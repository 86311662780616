import React from "react";
import Navbar from "../components/Navbar";
import Home from "../components/Home";
import Footer from "../components/Footer";

function Homepage() {
    return (
        <div>
            <Navbar />
            <Home />
            <Footer />
        </div>
    );
}

export default Homepage;
