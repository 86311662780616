import Homepage from "./containers/Homepage";
import InfectionPage from "./containers/InfectionPage";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import "./assets/css/Reset.css";
import "./assets/css/Style.css";

function App() {
    return (
        <Router>
            <Switch>
                <Route exact path="/">
                    <Homepage />
                </Route>
                <Route exact path="/infect">
                    <InfectionPage />
                </Route>
            </Switch>
        </Router>
    );
}

export default App;
